import { Field, FieldProps, Form, Formik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Input from '../../clientComponents/inputs';
import SelectDropdown from '../../clientComponents/selectDropdown';
import { listLanguages, selectClientProfile } from '../../clientSlices/profile';
import Button from '../../coachComponents/buttons';
import { coachAssessmentValidationSchema } from '../../coachHelpers';
import {
  listInterface,
  tagsInterface,
} from '../../coachInterfaces/sessionInterface';
import {
  assessmentInfo,
  clearAssessmentImageFileFormData,
  setAssessmentFormData,
  setAssessmentImageFileFormData,
} from '../../coachSlices/assessmentSlice';
import { packageInfo } from '../../coachSlices/packageSlice';
import {
  listCoachingTiming,
  sessionInfo,
} from '../../coachSlices/sessionSlice';
import { useAppSelector } from '../../hooks';

interface IFaqCard {
  sessionFormRef: any;
}

const Component: FC<IFaqCard> = ({ sessionFormRef }): JSX.Element => {
  const dispatch = useDispatch<any>();

  const [t] = useTranslation('common');
  const [monthInputValue, setMonthInputValue] = useState('');
  const [languageInputValue, setLanguageInputValue] = useState('');
  const [packageVideoName, setPackageVideoName] = useState('');
  const [packageVideoUrl, setPackageVideoUrl] = useState('');
  const { assessmentFormValue, assessmentImagefile } =
    useSelector(assessmentInfo);
  const { categories, categoriesTag } = useSelector(sessionInfo);
  const { duration } = useSelector(packageInfo);
  const { languages } = useSelector(selectClientProfile);
  const { lang } = useAppSelector((state) => state.language);
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const [showMaxLimitExceed, setShowMaxLimitExceed] = useState(false);

  useEffect(() => {
    dispatch(listLanguages());
    dispatch(listCoachingTiming());
  }, []);
  let languageListEn: { label: ''; value: '' }[] = [];
  let languageListAr: { label: ''; value: '' }[] = [];

  languages.map((item: any, index: number) => {
    languageListEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
    });
    languageListAr.push({
      label: item.nameInArabic,
      value: item.nameInArabic,
    });
  });
  let categoryEn: { label: string; value: string }[] = [];
  let categoryAr: { label: string; value: string }[] = [];

  categories?.map((item: listInterface) => {
    categoryEn.push({
      label: item?.nameInEnglish,
      value: item?.nameInEnglish,
    });
    categoryAr.push({
      label: item?.nameInArabic,
      value: item?.nameInEnglish,
    });
  });
  let categoryTagEn: { label: string; value: string }[] = [];
  categoriesTag?.map((item: any) => {
    categoryTagEn.push({
      label: item,
      value: item,
    });
  });
  let durationtEn: { label: string; value: string }[] = [];
  let durationAr: { label: string; value: string }[] = [];

  duration.map((item: any, index: number) => {
    durationtEn.push({
      label: item.nameInEnglish,
      value: item.nameInEnglish,
    });
    durationAr.push({
      label: item.nameInArabic,
      value: item.nameInEnglish,
    });
  });
  const handleFormSubmit = (data: any) => {
    if (data.description !== '<p><br></p>') {
    }
  };
  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    },
  };
  const formats = ['bold', 'italic', 'list', 'bullet'];

  const handleMonthInputChange = (event: string) => {
    setMonthInputValue(event);
  };

  const handleLanguageInputChange = (event: string) => {
    setLanguageInputValue(event);
  };
  const handleTagsInputChange = (
    event: tagsInterface[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue('categoryTags', event);
  };

  const uploadImageFiles = (e: any) => {
    let fileUrl: string = '';
    fileUrl = URL.createObjectURL(e.target.files[0]);
    dispatch(
      setAssessmentImageFileFormData({
        url: fileUrl,
        name: e.target.files[0].name,
        file: Array.from(e.target.files),
        fileId: '',
      })
    );
  };

  const uploadVideoFiles = (e: any) => {
    let fileUrl: string = '';
    fileUrl = URL.createObjectURL(e.target.files[0]);
    setPackageVideoUrl(fileUrl);
    setPackageVideoName(e.target.files[0].name);
  };
  const removeImage = () => {
    dispatch(clearAssessmentImageFileFormData());
  };
  const removeVideo = () => {
    setPackageVideoUrl('');
    setPackageVideoName('');
  };
  const getDurationLabel = (name: string) => {
    if (lang === 'en') {
      const item: tagsInterface[] = durationtEn.filter(
        (item: any) => item.value === name
      );
      return item[0]?.label;
    }
    if (lang === 'ar') {
      const item: tagsInterface[] = durationAr.filter(
        (item: any) => item.value === name
      );
      return item[0]?.label;
    }
  };
  const handleCategoryInputChange = (event: string) => {
    setCategoryInputValue(event);
  };
  return (
    <div className=" session-form-container ">
      <div className="w-full font-satoshi rtl:font-Almarai font-bold text-[34px]">
        {t('COACH_ASSESSMENT.ASSESSMENT_LANDING')}
      </div>
      <div className="w-full font-general rtl:font-Almarai font-normal text-base h-fit pb-4 text-[#687E8E] pt-4 border-b border-[#38383840]">
        {t('COACH_ASSESSMENT.INFO')}
      </div>
      <div className="w-full font-satoshi rtl:font-Almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_SESSION.BASIC_INFO')}
      </div>
      <Formik
        innerRef={sessionFormRef}
        initialValues={assessmentFormValue}
        validationSchema={coachAssessmentValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => {
          dispatch(setAssessmentFormData(values));

          return (
              <Form>
                <Input
                    id="FormTitle"
                    type="text"
                    name="title"
                    placeholder={t('COACH_SESSION.TITLE')}
                    onBlur={handleBlur}
                    error={touched.title && 1}
                    onChange={handleChange}
                    value={values.title}
                    errormessage={errors.title as string}
                />
                <Input
                    id="FormSubTitle"
                    type="text"
                    name="subTitle"
                    placeholder={t('COACH_SESSION.SUB_TITLE')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.subTitle && 1}
                    value={values.subTitle}
                    errormessage={errors.subTitle as string}
                />
                <Field name="description">
                  {(field: FieldProps) => (
                      <div className="relative">
                        {field?.field?.value === '<p><br></p>' ||
                        field?.field?.value === '' ? (
                            <span
                                className="text-red rtl:font-Almarai top-[11px] ltr:left-[15px] rtl:right-[15px] absolute cursor-text mb-[-5px]">
                        <span
                            className="input-text cursor-text font-general rtl:font-Almarai  text-gray text-17  font-normal text-opacity-80">
                          {' '}
                          {t('COACH_SESSION.DESCRIPTION')}
                        </span>{' '}
                              *
                      </span>
                        ) : (
                            <label
                                className="font-bold font-general rtl:font-Almarai text-xs text-secondary bg-white absolute ltr:left-3 rtl:right-3 top-[-10px] px-1 transition duration-200 label">
                              {t('COACH_SESSION.DESCRIPTION')}
                              <span className="text-red rtl:font-Almarai cursor-text ml-1">
                          *
                        </span>
                            </label>
                        )}

                        <ReactQuill
                            value={field?.field?.value}
                            onChange={(e: any) => {
                              setFieldValue(
                                  'description',
                                  e === '<p><br></p>' ? '' : e
                              );
                              setFieldTouched(field?.field?.name);
                            }}
                            onKeyDown={(e: any) => {
                              if (e.target.innerText.length > 300 && e.key !== 'Backspace') {
                                setShowMaxLimitExceed(true);
                                e.preventDefault();
                              } else {
                                setShowMaxLimitExceed(false);
                              }
                            }}
                            className={
                              field.meta.touched &&
                              (field?.field?.value === '<p><br></p>' ||
                                  field?.field?.value === '')
                                  ? 'description-box-error'
                                  : ''
                            }
                            theme="snow"
                            modules={modules}
                            formats={formats}
                        />
                        {field.meta.touched && showMaxLimitExceed && (<p className={"text-red"}>{t('COACH_SESSION.DESCRIPTION_ERROR')}</p>)}
                        {field.meta.touched &&
                            (field?.field?.value === '<p><br></p>' ||
                                field?.field?.value === '') && (
                                <p className="text-red rtl:font-Almarai font-normal text-12 pt-1.5 ">
                                  {t('COACH_SESSION.DESCRIPTION_REQUIRED')}
                                </p>
                            )}
                      </div>
                  )}
                </Field>
                <div className="w-full font-satoshi rtl:font-Almarai mb-8 font-bold mt-9 text-xl">
                  {t('COACH_ASSESSMENT.ASSESSMENT_DETAILS')}
                </div>
                <div className="mb-8">
                  <div
                      className="w-full font-general rtl:font-Almarai font-normal text-base  text-[#687E8E] flex items-center gap-3">
                    <label className="switch">
                      <input
                          name="onlyPackage"
                          type="checkbox"
                          checked={values.onlyPackage}
                          onChange={newValue => {
                            setFieldValue("onlyPackage", values.onlyPackage);
                          }
                          }
                      />
                      <span className="slider round"> </span>
                    </label>
                    {t('COACH_SESSION.PACKAGE_ONLY')}
                  </div>
                </div>
                {/* <div className="package-dropdown-row "> */}
                <div className="mb-8">
                  <SelectDropdown
                      id="FormLanguage"
                      name="language"
                      placeholder={t('COACH_SESSION.LANGUAGE')}
                      options={lang === 'en' ? languageListEn : languageListAr}
                      error={touched.language && 1}
                      errormessage={errors.language}
                      onBlur={handleBlur}
                      onChange={(opt: any) => {
                        setFieldValue('language', opt.value);
                      }}
                      onInputChange={handleLanguageInputChange}
                      inputValue={languageInputValue}
                      controlShouldRenderValue={true}
                      isValue={values.language}
                      optional={0}
                      defaultValue={
                          values.language !== '' && {
                            label: values.language,
                            value: values.language,
                          }
                      }
                  />
                </div>
                {/* <div className="mb-8">
                  <SelectDropdown
                    id="FormMonth"
                    name="deliveryDuration"
                    placeholder={t('COACH_ASSESSMENT.EXPECTED_DURATION')}
                    options={lang === 'en' ? durationtEn : durationAr}
                    error={touched.deliveryDuration && 1}
                    errormessage={errors.deliveryDuration}
                    onBlur={handleBlur}
                    onChange={(opt: any) => {
                      setFieldValue('deliveryDuration', opt.value);
                    }}
                    onInputChange={handleMonthInputChange}
                    inputValue={monthInputValue}
                    controlShouldRenderValue={true}
                    optional={0}
                    isValue={values.deliveryDuration}
                    defaultValue={
                      values.deliveryDuration !== '' && {
                        label: getDurationLabel(values.deliveryDuration),
                        value: values.deliveryDuration,
                      }
                    }
                  />
                </div> */}
                {/* </div> */}

                <div className="mb-8">
                  <SelectDropdown
                      id="FormCategory"
                      name="category"
                      placeholder={t('COACH_ASSESSMENT.CATEGORY')}
                      isSearchable={false}
                      options={lang === 'en' ? categoryEn : categoryAr}
                      error={touched.category && 1}
                      errormessage={errors.category}
                      onBlur={handleBlur}
                      onChange={(opt: any) => {
                        setFieldValue('category', opt.value);
                      }}
                      onInputChange={handleCategoryInputChange}
                      inputValue={categoryInputValue}
                      controlShouldRenderValue={true}
                      isValue={values.category}
                      optional={0}
                      defaultValue={
                          values.category !== '' && {
                            label: values.category,
                            value: values.category,
                          }
                      }
                  />
                </div>
                <div className="react-multi-react-tags">
                  <div className="mb-8">
                    <SelectDropdown
                        id="Formtags"
                        isMulti={true}
                        isCreatable={true}
                        options={categoryTagEn}
                        optional={1}
                        dropDown={true}
                        name="categoryTags"
                        placeholder={t('COACH_SESSION.TAGS')}
                        onBlur={handleBlur}
                        inn={
                          values?.categoryTags
                              ? values?.categoryTags[0]?.value
                              : null
                        }
                        onChange={(opt: any) => {
                          handleTagsInputChange(opt, setFieldValue);
                        }}
                        className="my-3"
                        isValue={
                          values?.categoryTags
                              ? values?.categoryTags[0]?.value
                              : null
                        }
                        defaultValue={
                            values?.categoryTags?.length > 0 && values?.categoryTags
                        }
                        controlShouldRenderValue={true}
                    />
                  </div>
                </div>

                <div
                    id="submitBtm"
                    className="flex justify-center items-center mt-2"
                >
                  <Button type="submit" className="hidden">
                    <div className="loader"/>
                    {t('LOGIN.LOG_IN')}
                  </Button>
                </div>
              </Form>
          );
        }}
      </Formik>
      <div className="w-full font-satoshi rtl:font-Almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_ASSESSMENT.ASSESSMENT_IMAGE')}
      </div>
      <div className=" session-image-container ">
        {assessmentImagefile.url === '' ? (
            <div className=" session-image-default">
              <img
                  className=""
                  src="/assets/default-img-icon.svg"
                  alt="bell-icon"
              />
            </div>
        ) : (
            <div className=" session-image h-56 relative">
              <div
                  onClick={removeImage}
                  className="w-5 cursor-pointer h-5 bg-[#ffffffbf] absolute top-[10px] ltr:right-[10px] rtl:left-[10px] rounded-[50%] flex justify-center items-center"
              >
                <img src="/assets/subscriptionPage/plusIcon.svg" alt=""/>
            </div>
            <img
              className="session-image h-56 object-cover rounded"
              src={assessmentImagefile.url}
              alt="bell-icon"
            />
          </div>
        )}
        <div className="session-image-desc rtl:font-Almarai">
          {t('COACH_SESSION.UPLOAD_IMAGE_TXT')}
          <div className=" session-upload">
            <label
              htmlFor="sessionImageUploadDiv"
              className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small w-fit cursor-pointer"
            >
              {t('COACH_SESSION.UPLOAD_IMAGE')}
            </label>
            <input
              className="hidden"
              id="sessionImageUploadDiv"
              accept="image/png, image/jpeg"
              type="file"
              onChange={(e: any) => uploadImageFiles(e)}
            />
            <span className="mx-3 font-general rtl:font-Almarai font-normal tex-base text-[#687E8E]">
              {assessmentImagefile.name !== ''
                ? assessmentImagefile.name
                : t('COACHING_DETAILS.NO_IMAGE_ADDED')}
            </span>
          </div>
        </div>
      </div>
      <div className="session-upload-mobile mt-4">
        <label
          // onClick={uploadPicHandler('sessionImageUploadDiv')}
          htmlFor="sessionImageUploadDiv"
          className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small w-fit cursor-pointer"
        >
          {t('COACH_SESSION.UPLOAD_IMAGE')}
        </label>
        <input
          className="hidden"
          id="sessionImageUploadDiv"
          accept="image/png, image/jpeg"
          type="file"
          onChange={(e: any) => uploadImageFiles(e)}
        />
        <span className="mx-3 font-general rtl:font-Almarai font-normal tex-base text-[#687E8E]">
          {assessmentImagefile.name !== ''
            ? assessmentImagefile.name
            : t('COACHING_DETAILS.NO_IMAGE_ADDED')}
        </span>
      </div>
      <div className="w-full font-satoshi rtl:font-Almarai mb-8 font-bold mt-9 text-xl">
        {t('COACH_ASSESSMENT.ASSESSMENT_VIDEO')}
        <span className="mx-1 font-general rtl:font-Almarai font-thin tex-base text-[#687E8E]">
          {t('COACH_SESSION.OPTIONAL')}
        </span>
      </div>
      <div className="session-image-container ">
        {packageVideoUrl === '' ? (
          <div className="session-image-default ">
            <img
              className=""
              src="/assets/default-img-icon.svg"
              alt="bell-icon"
            />
          </div>
        ) : (
          <div className="session-image h-56 relative">
            <div
              onClick={removeVideo}
              className="w-5 z-50 cursor-pointer h-5 bg-[#ffffffbf] absolute top-[10px] ltr:right-[10px] rtl:left-[10px] rounded-[50%] flex justify-center items-center"
            >
              <img src="/assets/subscriptionPage/plusIcon.svg" alt="" />
            </div>
            <video
              className="session-image h-56 object-cover z-10 rounded"
              controls
            >
              <source src={packageVideoUrl} />
            </video>
          </div>
        )}

        <div className=" session-image-desc rtl:font-Almarai">
          {t('COACH_SESSION.UPLOAD_VIDEO_TXT')}

          <div className="session-upload">
            <label
              htmlFor="sessionVideoUploadDiv"
              className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small w-fit cursor-pointer"
            >
              {t('COACH_SESSION.UPLOAD_VIDEO')}
            </label>
            <input
              className="hidden"
              id="sessionVideoUploadDiv"
              accept="video/*"
              type="file"
              onChange={(e: any) => uploadVideoFiles(e)}
            />
            <span className="mx-3 font-general rtl:font-Almarai font-normal tex-base text-[#687E8E]">
              {packageVideoName !== ''
                ? packageVideoName
                : t('COACHING_DETAILS.NO_VIDEO_ADDED')}
            </span>
          </div>
        </div>
      </div>
      <div className="session-upload-mobile mt-4">
        <label
          htmlFor="sessionVideoUploadDiv"
          className="bg-[#9EE0E6] border-[#9EE0E6] text-black btn-small w-fit cursor-pointer"
        >
          {t('COACH_SESSION.UPLOAD_VIDEO')}
        </label>
        <input
          className="hidden"
          id="sessionVideoUploadDiv"
          accept="video/*"
          type="file"
          onChange={(e: any) => uploadVideoFiles(e)}
        />
        <span className="mx-3 font-general rtl:font-Almarai font-normal tex-base text-[#687E8E]">
          {packageVideoName !== ''
            ? packageVideoName
            : t('COACHING_DETAILS.NO_VIDEO_ADDED')}
        </span>
      </div>
    </div>
  );
};
const CreateCoachAssessment = styled(Component)``;

export default CreateCoachAssessment;
